<script>
    import {post, get} from "./lib/Request.svelte";

    export let enabled = false;
    export let accountId = null;
    export let username = '';
    export let password = '';
    export let host = '';
    export let port = '';

    async function saveSettings() {
        const response = await post(`/pro/change-proxy?account_id=${accountId}`, {
            enabled: enabled ? 1 : 0,
            username,
            password,
            host,
            port
        });
    }

    let testIsLoading = false;
    let testStatus;
    async function testProxy() {
        testIsLoading = true;
        const response = await get(`/pro/test-proxy?account_id=${accountId}`);
        testStatus = response.status;
        testIsLoading = false;
    }
</script>

<div data-reactroot="" id="client-proxy"
     class="container container-center block-content block-content_tm block-content_tm-sm-left">
    <div class="widget-header">
        <h3>Личный прокси</h3>
        <div class="checkbox-block-m ">
            <label class="custom-big-checkbox">
                <input class="align-self-center" type="checkbox" value="1" bind:checked={enabled} on:change={saveSettings}>
                <span class="custom-big-checkbox__checkbox"></span>
            </label>
            <span class="labeltext Steps-label-checkbox_w">
            Активен
        </span>
        </div>
    </div>

    <div>
        <div class="form-group form-group-Customization-bottom">
            <input type="text"
                   class="form-control form-control_style"
                   autocomplete="false" placeholder="Логин"
                   bind:value={username} on:change={saveSettings}>
        </div>
        <div class="form-group form-group-Customization-bottom">
            <input type="text"
                   class="form-control form-control_style"
                   autocomplete="false" placeholder="Пароль"
                   bind:value={password} on:change={saveSettings}>
        </div>
        <div class="form-group form-group-Customization-bottom">
            <input type="text"
                   class="form-control form-control_style"
                   autocomplete="false" placeholder="Сервер"
                   bind:value={host} on:change={saveSettings}>
        </div>
        <div class="form-group form-group-Customization-bottom">
            <input type="text"
                   class="form-control form-control_style"
                   autocomplete="false" placeholder="Порт"
                   bind:value={port} on:change={saveSettings}>
        </div>
        <button id="test-proxy" class="btn btn-success btn-success-Customization-bottom" on:click|preventDefault={testProxy}>
            Проверить
            {#if testIsLoading}
                <img src="/img/ei_spinner-3 (3).svg" alt="/" class="loading">
            {/if}
        </button>
        {#if testStatus === 'ok'}
            <div class="alert alert-success proxy-message col-xs-12" id="proxy-success-message">
                Проверка успешно пройдена
            </div>
        {:else if testStatus === 'fail'}
            <div class="alert alert-danger proxy-message col-xs-12" id="proxy-error-message">
                Не удалось подключиться к прокси
            </div>
        {/if}
    </div>
</div>

<style>
    .widget-header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
</style>