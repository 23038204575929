<script>
    export let endpoint = '/site/request';

    export let open = false;
    export let showSended = false;

    export let name = '';
    export let email = '';
    export let theme = '';
    export let message = '';

    export let errorMessage = null;

    let pending = false;

    async function send() {
        pending = true;
        errorMessage = null;

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, theme, message})
        });
        const data = await response.json();
        pending = false;

        if (data.status === 'error') {
            if (data.message) {
                errorMessage = data.message;
            }
        } else if (data.status === 'ok') {
            showSended = true;
        }
    }

    function clear() {
        open = false;
        theme = '';
        message = '';
        showSended = false;
    }


</script>

<div class="wrapper">

    <div class="p-window" class:open={open}>
        <div class="p-header">
            Обратная связь
        </div>
        <form class="p-body" on:submit|preventDefault|stopPropagation={send}>
            {#if showSended}
                <br /><br />
                <div class="text-center" style="color: #333;">
                    Спасибо за обрещение.<br /> Мы вам ответим как можно скорее.<br /><br />
                    <a href="#" class="butt" on:click|preventDefault|stopPropagation={clear}>Закрыть</a>
                </div>
            {:else}
                <input disabled={pending} type="text" placeholder="Введите свое имя" bind:value={name}/>
                <input disabled={pending} type="text" placeholder="Введите свой e-mail" bind:value={email}/>
                <select disabled={pending} bind:value={theme}>
                    <option value="" disabled>Выберите тему обращения</option>
                    <option value="Финансовый вопрос">Финансовый вопрос</option>
                    <option value="Технический вопрос">Технический вопрос</option>
                    <option value="Общий вопрос">Общий вопрос</option>
                </select>
                <textarea disabled={pending} placeholder="Введите сообщение" bind:value={message}></textarea>
                <button disabled={pending}>Отправить</button>
                {#if errorMessage}
                    <div class="text-danger">
                        {errorMessage}
                    </div>
                {/if}
            {/if}

        </form>
    </div>

    <div class="button" on:click={() => { open = !open; }} class:open={open}>
        {#if open}
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0303 8.96967C9.73741 8.67678 9.26253 8.67678 8.96964 8.96967C8.67675 9.26256 8.67675 9.73744 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2626 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9697L13.0606 12L15.0303 10.0303C15.3232 9.73746 15.3232 9.26258 15.0303 8.96969C14.7374 8.6768 14.2625 8.6768 13.9696 8.96969L12 10.9394L10.0303 8.96967Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0574 1.25H11.9426C9.63424 1.24999 7.82519 1.24998 6.41371 1.43975C4.96897 1.63399 3.82895 2.03933 2.93414 2.93414C2.03933 3.82895 1.63399 4.96897 1.43975 6.41371C1.24998 7.82519 1.24999 9.63422 1.25 11.9426V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H12.0574C14.3658 22.75 16.1748 22.75 17.5863 22.5603C19.031 22.366 20.1711 21.9607 21.0659 21.0659C21.9607 20.1711 22.366 19.031 22.5603 17.5863C22.75 16.1748 22.75 14.3658 22.75 12.0574V11.9426C22.75 9.63423 22.75 7.82519 22.5603 6.41371C22.366 4.96897 21.9607 3.82895 21.0659 2.93414C20.1711 2.03933 19.031 1.63399 17.5863 1.43975C16.1748 1.24998 14.3658 1.24999 12.0574 1.25ZM3.9948 3.9948C4.56445 3.42514 5.33517 3.09825 6.61358 2.92637C7.91356 2.75159 9.62177 2.75 12 2.75C14.3782 2.75 16.0864 2.75159 17.3864 2.92637C18.6648 3.09825 19.4355 3.42514 20.0052 3.9948C20.5749 4.56445 20.9018 5.33517 21.0736 6.61358C21.2484 7.91356 21.25 9.62177 21.25 12C21.25 14.3782 21.2484 16.0864 21.0736 17.3864C20.9018 18.6648 20.5749 19.4355 20.0052 20.0052C19.4355 20.5749 18.6648 20.9018 17.3864 21.0736C16.0864 21.2484 14.3782 21.25 12 21.25C9.62177 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12C2.75 9.62177 2.75159 7.91356 2.92637 6.61358C3.09825 5.33517 3.42514 4.56445 3.9948 3.9948Z" />
            </svg>
        {:else}
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.7698 7.7688L13.2228 12.0551C12.5025 12.6116 11.4973 12.6116 10.777 12.0551L5.22998 7.7688" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
        {/if}
    </div>
</div>
<style>
  .wrapper {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 99;
  }

  .p-window {
    right: 8px;
    position: fixed;
    bottom: 110px;
    width: 400px;
    height: 370px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(35,35,35,0.25);
    display: none;
    flex-direction: column;
  }

  .p-window.open {
    display: flex;
  }

  .p-window .p-header {
    background-color: #74c733;
    color: #fff;
    padding: 12px 15px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .p-window .p-body {
    background-color: #f2f4fa;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .p-window .p-body input,
  .p-window .p-body select,
  .p-window .p-body textarea {
    margin: 5px 0;
    border: 1px solid rgb(210, 214, 230);
    z-index: unset;
    font-weight: 400;
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font-family: system-ui, sans-serif;
    font-size: 16px;
    transition: 0.25s;
    color: rgb(7, 7, 7);
    line-height: inherit;
    letter-spacing: inherit;
  }


  .p-window .p-body input:disabled,
  .p-window .p-body select:disabled,
  .p-window .p-body textarea:disabled {
    background-color: #e7e7e7;
  }

  .p-window .p-body input:disabled::placeholder,
  .p-window .p-body select:disabled::placeholder,
  .p-window .p-body textarea:disabled::placeholder {
    color: #6e6e6e;
  }

  .p-window .p-body textarea {
    line-height: 36px;
    padding: 8px 10px;
    height: 100px;
  }

  .p-window .p-body input,
  .p-window .p-body select {
    height: 36px;
  }

  .p-window .p-body button,
  .p-window .p-body a.butt {
    padding: 8px 10px;
    margin: 5px 0;
    background-color: #74c733;
    color: #fff;
    border: none;
    border-radius: 5px;
  }

  .button {
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    background-color: #74c733;
    transition: background-color 0.3s;
  }
  .button svg {
    stroke: #ffffff;
    width: 30px;
    height: 30px;
  }
  .button.open {
    background-color: rgb(150, 150, 150);
  }
  .button.open svg {
    fill: #fff;
  }
</style>